interface Schedule {
  day: string;
  timeslots: Timeslot[];
}

interface Timeslot {
  startTime: string;
  endTime: string;
  lessons: Lesson[];
}

interface Lesson {
  title: string;
  difficulty: string;
  instructor: Instructor;
  startTime?: string;
  endTime?: string;
}

interface Instructor {
  name: string;
  image: string;
}

export enum Difficulty {
    OPEN = 'open',
    BEGINNER = 'beginner',
    INTERMEDIATE = 'intermediate',
    ADVANCED = 'advanced',
}

export enum DifficultyBorder {
    OPEN = 'border-open',
    BEGINNER = 'border-beginner',
    INTERMEDIATE = 'border-intermediate',
    ADVANCED = 'border-advanced',
}

export enum DifficultyBackground {
    OPEN = 'bg-open',
    BEGINNER = 'bg-beginner',
    INTERMEDIATE = 'bg-intermediate',
    ADVANCED = 'bg-advanced',
}

export type { Schedule, Lesson, Timeslot, Instructor };